<script>
export default {
  name: 'GlueSliderImage',

  props: {
    devise: { type: Object },
    showGradient: {
      type: Boolean,
      default: true,
    },
  },

  data: () => {
    return {
      showVideo: false,
    }
  },

  computed: {
    sliderHeightStyles() {
      const styles = {}
      if (
        this.devise.fields.height
        && this.devise.fields.height.enabled
        && this.$voix.breakpoint?.value !== 'default'
        && this.$voix.breakpoint?.value !== 'sm'
      ) {
        styles.minHeight = `${this.devise.fields.height?.value}px`
      }

      if (
        this.devise.fields.maxHeight
        && this.devise.fields.maxHeight.enabled
        && this.$voix.breakpoint?.value !== 'default'
        && this.$voix.breakpoint?.value !== 'sm'
      ) {
        styles.maxHeight = `${this.devise.fields.maxHeight?.value}px`
      }

      return styles
    },

    sliderImageClasses() {
      let position = ['object-center']

      if (this.devise.fields.coverPosition && this.devise.fields.coverPosition?.value)
        position = [this.devise.fields.coverPosition?.value]

      if (!this.devise.fields.height || !this.devise.fields.height.enabled)
        position.push('h-[85vh]')

      if (this.devise.fields.coverType)
        return position.concat([this.devise.fields.coverType?.value])

      return position
    },
  },

  mounted() {
    if (
      this.devise.fields.wistiaId
      && this.devise.fields.videoMode
      && this.devise.fields.wistiaId.enabled
      && this.devise.fields.videoMode?.value === 'playAutomatically'
    ) {
      this.showVideo = true
    }
  },

  methods: {
    goToLink() {
      if (
        this.devise.fields.copyAppears
        && this.devise.fields.copyAppears?.value === 'notAtAll'
        && this.devise.fields.link.enabled
      ) {
        window.location.href = this.devise.fields.link?.value?.href
      }
    },
  },
}
</script>

<template>
  <div
    class="relative w-full h-full overflow-hidden"
    :class="{
      'cursor-pointer':
        devise.fields.copyAppears
        && devise.fields.copyAppears?.value === 'notAtAll'
        && devise.fields.link.enabled,
    }"
    :style="sliderHeightStyles"
    @click="goToLink"
  >
    <template v-if="(!showVideo && devise.fields.wistiaId.enabled && devise.fields.videoMode?.value === 'clickToActivate') || !devise.fields.wistiaId.enabled">
      <ClientOnly>
        <VoixMedia :field="devise.fields.image" class="w-full h-full" :class="sliderImageClasses" />
      </ClientOnly>
    </template>

    <div
      v-else-if="devise.fields.wistiaId?.value && devise.fields.wistiaId.enabled && showVideo"
      class="w-full flex items-center bg-black"
    >
      <div class="flex aspect-video items-center w-full pointer-events-auto">
        <glue-media-wistia
          :video-id="devise.fields.wistiaId?.value"
          :controls-location="devise.fields.videoControlsLocation?.value"
          :play-on-load="devise.fields.videoMode?.value === 'playAutomatically'"
          class="w-full h-full aspect-video object-contain"
        />
      </div>
    </div>

    <div
      v-if="
        devise.fields.copyAppears.value
          && devise.fields.copyAppears?.value !== 'left'
          && devise.fields.copyAppears?.value !== 'right'
      "
      class="bg-gradient-to-t absolute z-10 bottom-0 left-0 right-0"
      :class="{
        'from-black/50 to-black/0 h-[50%]': devise.fields.darkness?.value === 'light',
        'from-black/75 to-black/0 h-[75%]': devise.fields.darkness?.value === 'medium',
        'from-black to-black/0 h-[100%]': devise.fields.darkness?.value === 'dark',
      }"
    />

    <div class="relative md:absolute z-10 inset-0 grid justify-items-stretch">
      <div
        v-if="devise.fields.copyAppears.value && devise.fields.copyAppears?.value !== 'notAtAll'"
        class="flex flex-col justify-center w-full lg:w-1/3 bg-white px-12 pt-8 lg:pb-16"
        :class="{
          'justify-self-start':
            devise.fields.copyAppears && devise.fields.copyAppears?.value === 'left',
          'justify-self-end':
            devise.fields.copyAppears && devise.fields.copyAppears?.value === 'right',
        }"
      >
        <div
          class="uppercase text-3xl font-sans font-light leading-snug antialiased tracking-wide mt-2"
        >
          {{ devise.fields.title?.value }}
        </div>
        <div class="text-sm font-sans2 font-light antialiased mt-1">
          {{ devise.fields.description?.value }}
        </div>
        <a
          v-if="devise.fields.link.enabled"
          class="my-8 glue-btn btn-ghost border-zinc-600 text-zinc-700 uppercase btn-ghost body-11 tracking-widest py-3 px-6 pointer-events-auto"
          :href="devise.fields.link?.value?.href"
          :target="devise.fields.link?.value.target"
        >
          {{ devise.fields.link?.value.text }}
        </a>
      </div>
    </div>

    <div
      v-if="
        devise.fields.wistiaId
          && devise.fields.wistiaId.enabled
          && devise.fields.videoMode?.value === 'clickToActivate'
          && !showVideo
      "
      class="absolute z-20 inset-0 m-8 text-zinc-100 flex justify-center items-center"
    >
      <button @click="showVideo = true">
        <svg
          width="100"
          height="100"
          viewBox="0 0 62 62"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="31"
            cy="31"
            r="30"
            fill="black"
            fill-opacity="0.4"
            stroke="white"
            stroke-width="1.5"
          />

          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            transform="translate(24 21)"
            d="M16.4265 9.12584C17.1123 9.50685 17.1123 10.4932 16.4265 10.8742L1.48564 19.1746C0.819113 19.5449 0 19.063 0 18.3005L0 1.69951C0 0.93703 0.819113 0.455062 1.48564 0.825357L16.4265 9.12584Z"
            fill="white"
          />
        </svg>
      </button>
    </div>
  </div>
</template>
